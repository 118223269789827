* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body,
html {
    height: 100%;
    width: 100%;
}

h3 {
    font-family: "Open Sans", sans-serif;
    color: #111424;
    font-size: 22px;
    margin-bottom: .5em;
}


@media only screen and (min-width: 768px) and (max-width: 1024px) {
    h3 {
        font-size: 44px;
        text-align: center;
        margin-top: 1em;
        margin-bottom: 1em;
    }
}

@media only screen and (max-width: 767px) {
    h3 {
        font-size: 30px;
        text-align: center;
    }
}

section:not(#home-section) {
    margin-bottom: 200px;

}

button.button.primary,
a.button.primary,
a.project-button.primary {
    padding: .5em 2em;
    font-size: 24px;
    background: #10E7FE;
    border: 0;
    margin-top: 1em;
    color: #111424;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    font-weight: 900;
    border-radius: 5px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
    transition: transform .4s;
    text-decoration: none;
}

a.project-button.primary {
    margin-top: 3em !important;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
}

button.button.primary:hover,
a.button.primary:hover,
a.project-button.primary:hover {
    cursor: pointer;
    top: 549px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
}

button.button.secondary,
a.button.secondary,
.project-button.secondary {
    padding: 0.5rem 2.5rem;
    font-size: 18px;
    background: #10E7FE;
    border: 0;
    margin-top: 1em;
    color: #111424;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    font-weight: 700;
    border-radius: 5px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
    transition: transform .4s;
    text-decoration: none;
    font-family: "Open Sans", sans-serif;
    text-align: center;
}

button.button.secondary:hover,
a.button.secondary:hover,
.project-button.secondary:hover {
    cursor: pointer;
    top: 549px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .button.secondary {
        display: block;
        margin: 0 auto;
        margin-top: 2em;
    }
}

.section-headers,
.project-headers {
    font-family: "Montserrat", sans-serif;
    font-size: 2em;
    display: flex;
    text-align: center;
    justify-content: center;
    margin-top: 100px;
    font-weight: bold;
    text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
    svg {
        width: 40px;
        height: 40px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .section-headers {
        font-size: 3em;
    }
}

hr {
    width: 60px;
    height: 7px;
    background: #10E7FE;
    border-radius: 10px;
    border: none;
    display: block;
    margin: 0 auto;
    margin-bottom: 50px;
}

.two-col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 1280px;
    margin: 0 auto;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .item {
        max-width: 90vw;
    }
}

@media only screen and (max-width: 767px) {
    .item {
        overflow: hidden;
    }
}

@media only screen and (max-width: 767px) {
    .two-col {
        grid-template-columns: 1fr;
        max-width: 90vw;
        margin: 0 auto;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .two-col {
        grid-template-columns: 1fr;
        max-width: 100vw;
        justify-items: center;
    }
}

.body-text,
.experience-position,
.service-description {
    color: #111424;
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    font-weight: 300;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .body-text {
        color: #111424;
        font-family: "Open Sans", sans-serif;
        font-size: 24px;
        font-weight: 300;
    }
}