@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

/* Navigation */

.site-header {
    background: #fff;
    box-shadow: 0 10px 100px rgba(0, 0, 0, 0.1);
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 1em 3em;
    position: fixed;
    width: 100%;
    width: -webkit-fill-available;
    z-index: 999;
}

@media only screen and (max-width: 767px) {
    section:not(#home-section) {
        margin-bottom: 100px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .site-header {
        width: 100vw;
    }
}

@media only screen and (max-width: 767px) {
    .site-header {
        padding: 10px 5px;
    }
}

.nav-icon {
    align-items: center;
    display: flex;
    gap: 10px;
}

.navigation {
    align-items: center;
    display: flex;
    justify-content: end;
}

@media only screen and (max-width: 767px) {}

#nav-logo {
    height: 60px;
    width: auto;
}

@media only screen and (max-width: 767px) {
    #nav-logo {
        width: 70px;
        height: auto;
    }
}

#nav-name {
    color: #111424;
    font-family: "Montserrat", sans-serif;
    font-size: 2em;
    font-weight: 800;
    text-transform: uppercase;
}


@media only screen and (min-width: 768px) and (max-width: 1024px) {
    #nav-name {
        font-size: 1.5em;
    }
}

@media only screen and (max-width: 767px) {
    h1#nav-name {
        visibility: hidden;
    }
}

.navigation ul {
    display: flex;
    gap: 50px;
    list-style-type: none;
    text-decoration: none;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .navigation ul {
        gap: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .navigation ul {
        justify-content: end;
    }
}


.navigation ul li {
    color: #111424;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    transition: .2s;
}

.navigation ul li:hover {
    color: #10E7FE;
    cursor: pointer;
}

@media only screen and (max-width: 767px) {

    #home-link,
    #about-link,
    #projects-link,
    #experience-link,
    #services-link,
    #contact-link {
        display: none;
    }


}

#mobile-toggle {
    display: none;
    margin-top: 10px;
}

@media only screen and (max-width: 767px) {
    #mobile-toggle {
        display: block;
        margin-top: 5px;
    }
}

menu nav .mobile-navigation {
    position: fixed;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
}

.mobile-navigation.visible {
    transform: translateX(0);
}


nav.mobile-navigation {
    position: fixed;
    z-index: 1000;
    background: white;
    right: 0;
    list-style: none;
    height: 100vh;
    width: 300px;
}

nav.mobile-navigation ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 2em;
    text-align: center;

}

nav.mobile-navigation .footer-social-container {
    padding: 0;
    margin: 0;
    max-width: 100%;
}

nav.mobile-navigation .footer-social-links {
    color: #111424;
}

nav.mobile-navigation ul li {
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
}

svg.close-mobile {
    position: relative;
    top: 10px;
    left: 10px;
}

.background-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 998;
    display: none;
}

.background-overlay.visible {
    display: block;
}

/* Hero Section */
.hero-container {
    height: 75vh;
    overflow: hidden;
    position: relative;
    width: 100vw;
}

.hero-container::before {
    background-image: url('../assets/pattern-randomized.svg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    content: "";
    height: 100%;
    left: 0;
    opacity: .6;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.hero {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
    max-width: 1440px;
}

#hero-header,
.project-header {
    color: #111424;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-size: 82px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    #hero-header {
        font-size: 3em;
    }
}

@media only screen and (max-width: 767px) {
    #hero-header {
        font-size: 32px;
    }
}

#hero-subheader,
.project-subheader {
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 1em;
    margin-top: 1em;
    text-align: center;
    width: 65%;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    #hero-subheader {
        font-size: 1.5em;
    }
}

@media only screen and (max-width: 767px) {
    #hero-subheader {
        width: 95vw;
    }
}

.social-container {
    background: #fff;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
    border-radius: 0px 5px 5px 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: -moz-fit-content;
    height: fit-content;
    left: 0;
    padding: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: fit-content;
}

@media only screen and (max-width: 767px) {
    .social-container {
        display: none;
    }
}

a.social-links {
    text-decoration: none;
    color: #111424;
}

.social-links:hover {
    color: #10E7FE;
    cursor: pointer;
}

/* About */
#headshot {
    max-height: 550px;
    width: auto;
    margin: 0 auto;
    display: flex;
    border-radius: 5px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
}

@media only screen and (max-width: 767px) {
    #headshot {
        max-width: 90%;
        width: auto;
        margin: 0 auto;
        display: block;
        margin-bottom: 1em;
    }
}

span.body-text-span {
    font-weight: bold;
}

/* PROJECTS */


.project-card {
    margin-bottom: 6em;
    width: 100%;
}

.project-image {
    max-width: 500px;
}

@media only screen and (max-width: 767px) {
    h3.project-title {
        margin-top: 1.5em;
    }

    .project-image {
        max-width: 75vw;
        height: auto;
        margin: 0 auto;
        display: flex;
    }

    .tech {
        margin: 10px 10px 10px 0 !important;
    }
}

.tech-stack {
    display: flex;
    flex-wrap: wrap;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .tech-stack {
        justify-content: center;
    }

}

.tech,
.experience-date {
    background: rgba(153, 153, 153, 0.2);
    border-radius: 5px;
    color: #666;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
    margin-right: 1rem;
    padding: 5px 10px;
    text-transform: uppercase;
}

.tech:hover {
    cursor: default;
}

.project-disclaimer {
    max-width: 1280px;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    margin: 0 auto;
    margin-top: 5em;
    color: #111;
    font-style: italic;
    text-align: center;
}

@media only screen and (max-width: 767px) {
    p.project-disclaimer {
        max-width: 95vw;
    }
}

.project-span {
    font-weight: bold;
}

.project-disclaimer a {
    color: black;
}

.flickity-prev-next-button .flickity-button-icon {
    color: #10E7FE;
}

.flickity-page-dot.is-selected {
    background: #10E7FE !important;
}

@media only screen and (max-width: 767px) {
    .flickity-prev-next-button.previous {
        left: 0px !important;
        top: 70px;
    }

    .flickity-prev-next-button.next {
        right: 0px !important;
        top: 70px;
    }

    .flickity-page-dots {
        bottom: 0 !important;
    }
}

/* Experience */
.experience-card {
    border-radius: 5px;
    max-width: 500px;
    padding: 10px;
    margin: 0 auto;
    align-content: space-evenly;
    position: relative;
    background: #fff;
}

.experience-header {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.experience-hr {
    width: 10px;
    height: 550px;
    position: absolute;
    z-index: 0;
}

hr.experience-hr.hr-one {
    left: 24rem;
}

h3.experience-title {
    font-size: 28px;
    width: 500px;
    margin: 0 auto;
    margin-bottom: 1em;
}

@media only screen and (max-width: 767px) {
    h3.experience-title {
        width: auto;

    }
}

.experience-icon {
    width: auto;
    height: 25px;
    margin-top: 5px;
}

.experience-institution {
    margin-bottom: 0;
}

p.experience-date {
    width: fit-content;
    margin: 10px 0 10px 35px;

}

.experience-position,
.experience-date {
    margin-left: 35px;
}

@media only screen and (max-width: 767px) {
    .experience-institution {
        margin-bottom: 0;
        font-size: 20px;
        text-align: left;
    }
}

/* Services */
.services-container {
    max-width: 1280px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.services-icon {
    width: 200px;
    margin: 0 auto;
    margin-bottom: 10px;
}

.services-card {
    border: 1px solid transparent;
    width: 375px;
    height: 700px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

@media only screen and (max-width: 767px) {
    .services-container {
        max-width: 90vw;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr;
    }

    .services-card {
        width: 100%;
        height: fit-content;
        margin-bottom: 5em;
    }
}

h3.service-title {
    text-align: center;
}

/* Contact */
.contact-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 800px;
    margin: 0 auto;
}

@media only screen and (max-width: 767px) {
    .contact-form {
        max-width: 95vw;
        gap: 10px;
    }


}

#contact-section {
    height: 500px;
    position: relative;
    width: 100vw;
    margin-bottom: 0 !important;
}

#contact-section::before {
    background-image: url('../assets/pattern-randomized.svg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    content: "";
    height: 100%;
    left: 0;
    opacity: .6;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.input-group {
    display: flex;
    gap: 10px;
}

.input-group input,
.contact-form input,
.contact-form textarea {
    width: 100%;
    height: 50px;
    padding: 8px;
    border: 1px solid;
    border-radius: 5px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
}

@media only screen and (max-width: 767px) {

    .input-group input,
    .contact-form input,
    .contact-form textarea {
        width: 100%;
        height: 50px;
        padding: 8px;
        border: 1px solid;
        margin-bottom: 1em;
        border-radius: 5px;
    }
}

.contact-form textarea {
    height: 100px;
}

.contact-form button {
    padding: 10px;
    cursor: pointer;
}

@media only screen and (max-width: 767px) {
    .contact-form-top {
        display: block !important;
    }

    .contact-form {
        gap: 0
    }
}

.contact-form-top {
    display: flex;
    gap: 10px
}

.contact-form-top #contact-section input[type="text"],
#contact-section input[type="email"],
textarea {
    border-radius: 5px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
}

.contact-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contact-container .section-headers {
    margin-top: 0;
}

/* Footer */
section#footer-section {
    background: #111424;
    margin-top: 50px;
}

#footer .item {
    max-width: 500px;
    margin: 0 auto;
}

.footer-header {
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-size: 2em;
    font-weight: 800;
    text-transform: uppercase;
    padding-top: 1em;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    h1.footer-header {
        text-align: center;
    }
}

@media only screen and (max-width: 767px) {
    h1.footer-header {
        text-align: center;
    }
}

p.body-subtext.footer {
    color: #fff;
    font-family: "Open Sans", sans-serif;
    margin-top: 1em;
}

@media only screen and (max-width: 767px) {
    p.body-subtext.footer {
        text-align: center;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    p.body-subtext.footer {
        text-align: center;
    }
}

.footer-social-container {
    display: flex;
    gap: 15px;
    margin-top: 1em;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .footer-social-container {
        justify-content: center;
    }
}

@media only screen and (max-width: 767px) {
    .footer-social-container {
        justify-content: center;
    }
}

.footer-social-links {
    color: #fff;
    text-decoration: none;
}

.footer-social-links:hover {
    cursor: pointer;
    color: #10E7FE;
}

.copyright {
    display: block;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 500;
    margin-top: 3em;
    padding-bottom: 1em;
    text-align: center;
}

/* Blog */
.project-image {
    margin-top: 2em;
}

.project-body {
    max-width: 1024px;
    width: 90%;
    display: block;
    margin: 0 auto;
}

.project-headers {
    font-size: 1.5em !important;
    margin-bottom: 20px !important;
    display: inline-block !important;
    text-align: left !important;
    margin-top: 20px !important;
}

.project-body-text {
    display: inline-block;
    text-align: left;
    margin-left: 0 !important;
}

.project-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 50px;
    padding: 50px 0;
}

@media only screen and (max-width: 767px) {
    .project-button-container {
        display: block;
    }

    .project-button-container a {
        width: 300px !important;
        text-align: center !important;
    }

    h1.project-header {
        margin-top: 5em;
        font-size: 44px;
    }

    h2.project-subheader {
        font-size: 18px;
        width: 90%;
        margin-top: 1em;

    }
}